import React, { useState } from "react";
import { useRouter } from "next/router";
import { CommentBox } from "artisn-ui-react";

import InfoActionModal from "components/global/InfoActionModal/InfoActionModal";
import Styles from "./RateModal.styles";
import { Answer, AnswerWithImage, RatingData } from "./RateModal.types";
import { RateModalProps as Props } from "./RateModal.types";
import StarRating from "./StarRating/StarRating";
import RatingCheckbox from "./RatingCheckbox/RatingCheckbox";
import { useGetRatingOrderQuestions } from "services/rating/rating.service.hooks";
import { useRateOrder } from "services/rating/rating.service.hooks";
import useAuth from "contexts/auth/auth.context.hooks";
import { useFetchUser } from "services/user/user.service.hooks";

import RateOrder from "../../../../public/assets/images/order-rating.svg";
import CheckCircle from "../../../../public/assets/images/checked-empty-circle.svg";

const RateModal: React.FC<Props> = props => {
  const { orderDetails, closeRate } = props;
  const { push } = useRouter();
  const [step, setStep] = useState(1);
  const [stars, setStars] = useState(0);
  const [title, setTitle] = useState("¿Cómo estuvo tu orden?");
  const ratingFormId = stars > 3 ? 9 : 8;
  const { data: ratingOrderForm } = useGetRatingOrderQuestions(ratingFormId);
  const { sections } = ratingOrderForm ?? {};
  const { fields } = sections ? sections[0] : sections ?? {};
  const { type } = fields ? fields[0] : fields ?? {};
  const { attributes } = type ?? {};
  const { options: ratingQuestions } = attributes ?? {};
  const postRateOrder = useRateOrder();
  const { mutateAsync: rateOrder } = postRateOrder;
  const { id } = orderDetails ?? {};
  const [selectedOptions, setSelectedOptions] = useState<Answer[]>([]);
  const [comment, setComment] = useState("");
  const { uid } = useAuth();
  const { data: user } = useFetchUser();
  const images = {
    negative: [
      {
        id: 1,
        icon: "/assets/images/temp/rating-01.svg"
      },
      {
        id: 2,
        icon: "/assets/images/temp/rating-02.svg"
      },
      {
        id: 3,
        icon: "/assets/images/temp/rating-03.svg"
      },
      {
        id: 4,
        icon: "/assets/images/temp/rating-04.svg"
      },
      {
        id: 5,
        icon: "/assets/images/temp/rating-05.svg"
      },
      {
        id: 6,
        icon: "/assets/images/temp/rating-06.svg"
      }
    ],
    positive: [
      {
        id: 1,
        icon: "/assets/images/temp/rating-07.svg"
      },
      {
        id: 2,
        icon: "/assets/images/temp/rating-08.svg"
      },
      {
        id: 3,
        icon: "/assets/images/temp/rating-09.svg"
      },
      {
        id: 4,
        icon: "/assets/images/temp/rating-10.svg"
      }
    ]
  };

  const answersWithImages: AnswerWithImage[] = ratingQuestions?.map(
    (question: any, index: number) => {
      if (ratingFormId === 8) {
        return { ...question, icon: images.negative[index].icon };
      }
      return { ...question, icon: images.positive[index].icon };
    }
  );

  const getFormData = (field: any) => {
    let formData;
    const { type, label, id } = field;
    const { tag } = type;
    switch (tag) {
      case "Option":
        formData = {
          id,
          label,
          value: selectedOptions
        };
        break;
      case "TextArea":
        formData = {
          id,
          label,
          value: comment
        };
        break;
      default:
        break;
    }
    return formData;
  };

  const rateOrderHandler = () => {
    const formData: any = [];
    if (fields) {
      fields.map((field: any) => {
        const formDataField = getFormData(field);
        if (formDataField) {
          formData.push(formDataField);
        }
        return formDataField;
      });
    }
    const ratingData: RatingData = {
      ownerId: uid,
      ownerData: {
        name: `${user?.name ?? "ANONIMO"} ${user?.lastname ?? ""}`
      },
      additionalInfo: {
        rate: stars
      },
      relatedEntityId: id?.toString(),
      formData,
      completed: selectedOptions.length ? 1 : 0,
      activity: ratingFormId
    };

    rateOrder(ratingData);
  };

  const checkboxHandler = (checked: boolean, answer: Answer) => {
    if (checked) {
      setSelectedOptions(prev =>
        prev.length > 0 ? [...prev, answer] : [answer]
      );
      return;
    }

    setSelectedOptions(prev => [
      ...prev.filter(ans => ans.value !== answer.value)
    ]);
  };

  const setStepHandler = () => {
    setStep(prev => prev + 1);
    if (step === 1) {
      setTitle(
        stars <= 3 ? "¿Algo salió mal?" : "¿Que fué lo que más te gustó?"
      );
      return;
    }
    if (step === 2) {
      rateOrderHandler();
    }

    setTitle("");
  };

  const rateNode = () => {
    return (
      <div className="RateModal__rate">
        <RateOrder className="RateModal__rate__img" />
        <div className="RateModal__rate__order">
          <span>Orden:</span>
          <span>#{id}</span>
        </div>
      </div>
    );
  };

  const answersNode = () => {
    return (
      <>
        <div className="RateModal__answers">
          {answersWithImages &&
            answersWithImages.map((answer, index) => {
              return (
                <RatingCheckbox
                  key={index}
                  answer={answer}
                  toggleCheckbox={checkboxHandler}
                />
              );
            })}
        </div>
        {stars <= 3 && (
          <CommentBox
            className="Checkout__comment"
            label="Agregar un comentario"
            value={comment}
            placeholder="Escribe un comentario"
            rows={3}
            onChange={e => setComment(e.target.value)}
            name="checkout-comment"
          />
        )}
      </>
    );
  };

  const finishNode = () => {
    return (
      <div className="RateModal__finish">
        <CheckCircle />
        <p className="RateModal__finish__text">¡Gracias por tus comentarios!</p>
      </div>
    );
  };

  const closeHandler = () => {
    if (step === 3) {
      window.location.reload();
      closeRate?.();
      return;
    }
    closeRate?.();
  };

  return (
    <Styles className="RateModal">
      <InfoActionModal
        className="RateModal"
        isRating={true}
        hideButtons={step === 3}
        opened={true}
        cancelAction={() => push("/profile/support")}
        cancelText="Ayuda"
        confirmAction={setStepHandler}
        confirmText="Calificar"
        title={title}
        description=""
        onClose={closeHandler}
        disabled={!stars}
      >
        {step === 1 && rateNode()}
        {(step === 1 || step === 2) && (
          <StarRating setRating={setStars} rating={stars} />
        )}
        {step === 2 && answersNode()}
        {step === 3 && finishNode()}
      </InfoActionModal>
    </Styles>
  );
};

RateModal.defaultProps = {};

export default RateModal;
