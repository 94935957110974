import styled from "styled-components";

import { RatingCheckboxStyledProps as Props } from "./RatingCheckbox.types";

const RatingCheckboxStyled = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: center;

  .RatingCheckbox {
    &__image {
      width: 7rem;
      height: 7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--palette-gray-s0-l75);
      border-radius: 1rem;

      &--active {
        background-color: var(--palette-primary);
      }

      &__svg {
        width: 4.8rem;
        height: 4.8rem;
        -webkit-mask-image: ${props => `url(${props.svg})`};
        mask-image: ${props => `url(${props.svg})`};
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: center;
        background-color: var(--palette-primary);

        &--active {
          background-color: var(--palette-white);
        }
      }
    }
    &__checkbox {
      position: absolute;
      width: 100%;

      .Checkbox__check {
        width: 7rem;
        height: 7rem;
        border: none;

        &--active {
          background: none;

          svg {
            display: none;
          }
        }
      }
    }
  }
`;

export default RatingCheckboxStyled;
