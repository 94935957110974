import styled from "styled-components";

import { StarRatingStyledProps as Props } from "./StarRating.types";

const StarRatingStyled = styled.div<Props>`
  display: flex;
  margin-bottom: 2.4rem;

  .StarRating {
    &__star {
      cursor: pointer;
      font-size: 2.4rem;
      line-height: 1;

      :not(:last-child) {
        margin-right: 3.2rem;
      }

      &--on {
        color: var(--palette-yellow-s100-l55);
      }

      &--off {
        color: var(--palette-lightgrey-s5-l90);
      }
    }
  }
`;

export default StarRatingStyled;
