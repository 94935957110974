import { useMutation, useQuery, useQueryClient } from "react-query";

import useAuth from "contexts/auth/auth.context.hooks";
import { getRatingOrderQuestions, rateOrder } from "./rating.service";
import { getOrderRatingStatus } from "./rating.service";
import { notify } from "utils/common.utils";

export const useGetOrderRatingStatus = (orderId: number | undefined) => {
  const { uid } = useAuth();

  return useQuery(
    [uid, "orders", "history", orderId],
    () => getOrderRatingStatus(orderId),
    {
      enabled: !!uid,
      staleTime: 60 * 1000 * 60
    }
  );
};

export const useGetRatingOrderQuestions = (entityId: number) => {
  const { uid } = useAuth();

  return useQuery(
    [uid, "orders", "history", entityId],
    () => getRatingOrderQuestions(entityId),
    {
      enabled: !!uid,
      staleTime: 60 * 1000 * 60,
      onError: (e: Error) => {
        notify(e, "Get Order history request");
      }
    }
  );
};

export const useRateOrder = () => {
  const queryClient = useQueryClient();
  const { uid } = useAuth();

  return useMutation((ratingPayload: any) => rateOrder(ratingPayload), {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries([
        uid,
        "orders",
        "history",
        "IN_PROGRESS"
      ]);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries([
        uid,
        "orders",
        "history",
        undefined,
        "IN_PROGRESS"
      ]);
      queryClient.invalidateQueries([
        uid,
        "orders",
        "history",
        undefined,
        "DONE"
      ]);
    }
  });
};
