import React, { ChangeEvent, useState } from "react";

import Styles from "./RatingCheckbox.styles";
import { RatingCheckboxProps as Props } from "./RatingCheckbox.types";
import Checkbox from "components/global/Checkbox/Checkbox";
import { Answer } from "components/profileOrder/RateModal/RateModal.types";

const RatingCheckbox: React.FC<Props> = props => {
  const { answer, toggleCheckbox } = props;
  const { icon, text, value } = answer;
  const [isChecked, setIsChecked] = useState(false);
  const checkboxHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const answer: Answer = { value, text };
    setIsChecked(checked);
    toggleCheckbox(checked, answer);
  };

  return (
    <Styles className="RatingCheckbox" svg={icon}>
      <div
        className={`RatingCheckbox__image ${
          isChecked ? "RatingCheckbox__image--active" : ""
        }`}
      >
        <div
          className={`RatingCheckbox__image__svg ${
            isChecked ? "RatingCheckbox__image__svg--active" : ""
          }`}
        ></div>
      </div>
      <Checkbox
        name={value}
        className="RatingCheckbox__checkbox"
        label={value}
        defaultChecked={false}
        onChange={event => checkboxHandler(event)}
      />
    </Styles>
  );
};

RatingCheckbox.defaultProps = {};

export default RatingCheckbox;
