import styled from "styled-components";

import { RateModalStyledProps as Props } from "./RateModal.types";

const RateModalStyled = styled.div<Props>`
  .RateModal {
  }
`;

export default RateModalStyled;
