import React, { useState } from "react";

import Styles from "./StarRating.styles";
import { StarRatingProps as Props } from "./StarRating.types";

const StarRating: React.FC<Props> = props => {
  const { rating, setRating } = props;
  const [hover, setHover] = useState(0);

  return (
    <Styles className="StarRating">
      {[...Array(5)].map((_, index) => {
        index += 1;
        return (
          <div
            key={index}
            className={`StarRating__star StarRating__star--${
              index <= (hover || rating) ? "on" : "off"
            }`}
            onClick={() => setRating(index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
          >
            &#9733;
          </div>
        );
      })}
    </Styles>
  );
};

StarRating.defaultProps = {};

export default StarRating;
