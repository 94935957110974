// Rating services
import CONSTANTS from "config/constants";
import { getState } from "services/state";
import { notify } from "utils/common.utils";
import { buildHeaders } from "utils/services.utils";

const { ARTISN } = CONSTANTS;
const { ACCOUNT_ID } = ARTISN;

export const getOrderRatingStatus = async (
  orderId: number | undefined
): Promise<any> => {
  if (!orderId) {
    return;
  }
  const { axiosDefault } = getState();
  try {
    const { data } = await axiosDefault.get(
      `api/activities/activityEntry/findByRelatedEntity/${orderId}`,
      {
        headers: await buildHeaders({ workspace: ACCOUNT_ID })
      }
    );

    return data;
  } catch (e) {
    const error = e?.response?.data;
    if (error?.code === 422) {
      throw new Error(e.message);
    }
    notify(e, "Get Order Rating Status request");
    throw new Error(e.message);
  }
};

export const getRatingOrderQuestions = async (
  entityId: number
): Promise<any> => {
  const { axiosDefault } = getState();
  try {
    const { data } = await axiosDefault.get(
      `api/activities/activity/${entityId}`,
      {
        headers: await buildHeaders({ workspace: ACCOUNT_ID })
      }
    );

    return data.form;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const rateOrder = async (ratingPayload: any): Promise<any> => {
  const { axiosDefault } = getState();
  try {
    const { data } = await axiosDefault.post(
      `api/activities/activityEntry`,
      { ...ratingPayload },
      {
        headers: await buildHeaders({ workspace: ACCOUNT_ID })
      }
    );

    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};
